<template>
    <footer class="page">
        <div class="ue_info">
          <b-container>
            <b-row>
              <b-col class="text-center">
                <img style="width: 100%; height: auto" src="./../assets/media/ue.png" class="logo" />
              </b-col>
            </b-row>
          </b-container>
        </div>
        <div class="contact_info">
          <b-container>
            <b-row>
              <b-col>
                <router-link to="/">
                  <img src="./../assets/media/logo/color.svg" class="logo" />
                </router-link>
              </b-col>
            </b-row>
          </b-container>
        </div>
        <b-container>
            <b-row class="py-5">
                <b-col>

                </b-col>
            </b-row>
            <b-row class="pt-5 pb-4 pb-lg-7">
                <b-col cols="12" :md="12 / nav_menus.length" class="mb-5 mb-md-0" v-for="(nav, i) in nav_menus" :key="'nav_'+i" >
                    <nav-menu :title="nav.title" :items="nav.links"></nav-menu>
                </b-col>
            </b-row>
            <b-row class="pt-0 pt-lg-4" align-v="center">
                <b-col order="1" order-lg="1" cols="12" lg="4" class="text-center text-lg-left">
                    <p class="h6 copyright">Gmina Skoczów 2021. Design &amp; code by <a href="https://expansja.pl/" target="_blank">Expansja Advertising</a></p>
                </b-col>
                <b-col order="2" class="text-center" cols="12" lg="4">
                  <p class="h6 copyright mt-4 mb-4 mb-lg-0 mt-lg-0">Liczba wyświetleń: {{views_counter.data.counter}}</p>
                </b-col>
                <b-col order="3" order-lg="3" cols="12" lg="4" class="text-center text-lg-right pb-5 pb-lg-0">
                    <ul v-if="docs" class="docs_menu lg-5 mb-lg-0">
                        <li v-for="(doc, i) in ['privacy_policy', 'cookies', 'accessibility']" :key="'doc_'+i">
                            <a class="h6" v-if="docs[doc]" :href="docs[doc].url" :target="docs[doc].target">{{ docs[doc].title }}</a>
                        </li>
                    </ul>
                </b-col>
            </b-row>
        </b-container>
    </footer>
</template>

<script>
    import NavMenu from "./footer/NavMenu";

    export default {
        name: "PageFooter",
        components: {NavMenu},
        data() {
          return {
            views: 0
          }
        },
        computed: {
            contact() {
                return this.$store.getters.setting('contact')
            },
            nav_menus() {
                return this.$store.getters.setting('nav')
            },
            docs() {
                return this.$store.getters.setting('docs')
            },
            views_counter() {
              return this.$store.getters.views
            }
        },
    }
</script>

<style lang="scss">
    .ue_info {
      background: $white;
    }

    .contrast {
      .contact_info {
        background: $black;
        color: $white;
      }
    }

    .contact_info {
      padding: 3rem 0;
      background: #F0F4FB;
    }

    footer.page{
        background: $primary;
        color: $white;
        padding-bottom: 3rem;

        a.h5{ color: $blue-100; }
        .logo{ height: 64px; }

        .copyright{
            &, *{ color: $white; }
            @extend %header-6;
            margin: 0;
        }

        hr {
            margin: 0 auto;
            border-color: $gray-800;
        }

        .docs_menu {
            list-style: none;
            margin-bottom: 0;
            padding-left: 0;
            display: inline-flex;
            flex-direction: column;

            li {
                line-height: 0;
                padding: 0 0 10px;

                &:last-child { padding-bottom: 0; }
                a{ color: $white; }
            }

            @include media-breakpoint-up(lg){
                 flex-direction: row;

                li {
                    border-right: 1px solid $white;
                    padding: 0 1.5rem;

                    &:last-child{ border-right: none; }
                }
            }
        }

        h5{
            margin-bottom: 16px;

            @include media-breakpoint-up(lg){ margin-bottom: 24px; }
        }
    }

    .contrast footer.page{
        background: $black;
        color: $white;

        .copyright{
            &, *{ color: $blue-100; }
        }

        .docs_menu{
            li a{
                color: $blue-100;
            }
        }
    }
</style>
