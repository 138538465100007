import Vue from 'vue'
import Vuex from 'vuex'
import ApiClient from './wp_api_client'

const api = new ApiClient(process.env.VUE_APP_API_URL)

Vue.use(Vuex)

function generateGetters(data) {
  let af = {};
  data.map(i => af[i.getterName] = state => slug => state.content[i.varName].filter(p => p.slug === slug)[0])
  return af
}

export default new Vuex.Store({
  state: {
    content: {
      frontpage: null,
      pages: [],
      posts: [[]],
    },
    fontsize: 16,
    nav_menus: [],
    settings: null,
    contrast: false,
    views: 0,
    currentPage: 1,
    totalPages: 0,
    totalPosts: 0,
    perPage: 12,
    isLoading: false,
  },

  getters: {
    ...generateGetters([
      {getterName: 'page', varName: 'pages'},
      {getterName: 'post', varName: 'posts'},
    ]),
    menu: state => location => {
      return state.nav_menus.filter(p => p.location === location)[0]
    },
    setting: state => key => {
      if(!state.settings) return
      return state.settings.acf[key]
    },
    views(state) {
      return state.views
    },
    currentPosts: (state) => {
      const page = state.currentPage - 1;
      return state.content.posts[page] || [];
    },
  },

  mutations: {
    SET_FRONTPAGE(state, payload) {
      state.content.frontpage = payload
    },
    SET_SETTINGS(state, payload) {
      Vue.set(state, 'settings', payload)
    },
    SET_PAGE(state, payload) {
      Vue.set(state.content, 'pages', [payload, ...state.content.pages])
    },
    SET_VIEWS(state, payload) {
      state.views = payload
    },
    SET_POST(state, payload) {
      Vue.set(state.content, 'posts', [payload, ...state.content.posts])
    },
    SET_POSTS(state, posts) {
      // let uniquePosts = posts.data.filter(p => !state.content.posts[state.currentPage - 1].find(cp => cp.id === p.id))
      let uniquePosts = posts.data
      Vue.set(state.content.posts, state.currentPage - 1, [...uniquePosts])
    },
    SET_CURRENT_PAGE(state, page) {
      state.currentPage = page;
    },
    SET_TOTAL_PAGES(state, totalPages) {
      state.totalPages = totalPages;
    },
    SET_TOTAL_POSTS(state, totalPosts) {
      state.totalPosts = totalPosts;
    },
    SET_LOADING(state, isLoading) {
      state.isLoading = isLoading;
    },
    UPDATE_POSTS_PAGE(state, payload) {
      let uniquePosts = payload.posts.filter(p => !state.content.posts.find(cp => cp.id === p.id))
      let posts = [...state.content.posts, ...uniquePosts]
      Vue.set(state.content, 'posts', posts.sort((a,b) => new Date(b.date) - new Date(a.date)))
    },
    CLEAR_LAST_QUERY(state) {
      state.content.lastPostsQuery = null
    },
    SET_PAGES(state, payload) {
      Vue.set(state.content, 'pages', payload.data)
    },
    SET_ALL_BLOG(state, payload) {
      Vue.set(state.content, 'posts', payload)
    },
    SET_NAV_MENU(state, payload) {
      Vue.set(state, 'nav_menus', [payload, ...state.nav_menus])
    },
    SWITCH_CONTRAST(state) {
      state.contrast = !state.contrast
    },
    INC_FONT(state) {
      if(state.fontsize >= 12 && state.fontsize < 24){
        state.fontsize += 2
      }
    },
    DEC_FONT(state) {
      if(state.fontsize > 12 && state.fontsize <= 24){
        state.fontsize -= 2
      }
    },
    RESET_FONT(state) {
      state.fontsize = 16
    }
  },

  actions: {
    init({ dispatch }) {
      dispatch('getNavMenu', 'topbar')
      dispatch('getSettings')
      dispatch('getViews')
    },
    getFrontpage({ state }) {
      if(state.content.frontpage) return
      api.getFrontpage()
          .then((response) => {
            this.commit('SET_FRONTPAGE', response.data)
          })
          .catch((error) => console.log(error))
    },
    getPage({ getters, state, dispatch }, slug) {
      if(state.content.pages.length === 0) dispatch('getAllPages');
      else getPost(getters, slug, 'SET_PAGE', 'pages', this)
    },
    getPost({ getters }, slug) {
      getPost(getters, slug, 'SET_POST', 'posts', this)
    },
    getViews() {
      api.getViewsCounter()
          .then((response) => {
          this.commit('SET_VIEWS', response.data)
        })
          .catch((error) => console.log(error))
    },
    getAllPages() {
      getAllPosts('SET_PAGES', {type: 'pages'}, this)
    },
    getAllPosts({ commit, state }, { type, page, perPage }) {
      if (!state.content.posts[page - 1] || state.content.posts[page - 1].length === 0) {
        commit('SET_LOADING', true);
        getAllPosts('SET_POSTS', { type, page, perPage }, this)
      }
      commit('SET_CURRENT_PAGE', page);
    },
    getNavMenu({getters}, location) {
      if(getters.menu(location)) return
      api.getNavMenu(location)
          .then((response) => {
            this.commit('SET_NAV_MENU', {
              location: location,
              items: response.data
            })
          })
          .catch((error) => console.log(error))
    },
    getSettings({ getters }) {
      if(getters.settings) return
      api.getSettings()
          .then((response) => {
            this.commit('SET_SETTINGS', response.data)
          })
          .catch((error) => console.log(error))
    }
  },

  modules: {
  }
})

function getPost(getters, slug, mutation, type, _this) {
  if(getters.page(slug)) return
  api.getPost(type, slug)
      .then((response) => {
        _this.commit(mutation, response)
      })
      .catch((error) => console.log(error))
}

function getAllPosts(mutation, { type, page = 1, perPage = 100 }, _this) {
  api.getAllPosts(type, page, perPage)
      .then((response) => {
        _this.commit(mutation, response);
        _this.commit('SET_LOADING', false);
        _this.commit('SET_TOTAL_PAGES', parseInt(response.headers['x-wp-totalpages']));
        _this.commit('SET_TOTAL_POSTS', parseInt(response.headers['x-wp-total']));
      })
      .catch((error) => console.log(error))
}
