import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/aktualnosci',
    redirect: { name: 'news' }
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('../views/News')
  },
  {
    path: '/:slug',
    name: 'page',
    component: () => import('../views/Page.vue')
  },
  {
    path: '/news/:slug',
    name: 'single-news',
    component: () => import('../views/Single'),
    meta: { type: 'news' }
  }
]

const router = new VueRouter({
  mode: 'history',
  hash: false,
  routes,
  scrollBehavior: function () {
    return { x: 0, y: 0 }
  }
})

export default router
