<template>
  <div :class="{'contrast' : $store.state.contrast}" :style="'font-size:' + $store.state.fontsize + 'px'">
    <navigation-bar />
    <main>
      <router-view/>
    </main>
    <page-footer />
  </div>
</template>

<script>
  import NavigationBar from "./components/NavigationBar";
  import PageFooter from "./components/PageFooter";

  export default {
    components: {NavigationBar, PageFooter},
    mounted() {
      this.$store.dispatch('init')
    }
  }
</script>

<style lang="scss">
  @import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');
  @import "assets/scss/app";
</style>