<template>
    <nav :class="scrollTop >= 0 ? 'scroll-top' : 'scroll-bottom'" class="navigation-bar">
        <b-container>
            <b-row align-h="between" align-v="center">
                <b-col order="1" cols="auto">
                    <router-link to="/">
                        <img src="./../assets/media/logo/color.svg" class="logo" />
                    </router-link>
                </b-col>
                <b-col order="2" order-lg="3" class="d-flex d-lg-none justify-content-end">
                    <button class="hamburger-menu" @click="collapse = !collapse" :class="collapse ? 'open' : ''">
                        <span></span>
                    </button>
                </b-col>
                <b-col cols="12" lg order="3" order-lg="2" class="collapse-menu">
                    <div class="d-lg-flex align-items-start align-items-lg-end flex-column" :class="collapse ? 'd-flex' : 'd-none'">
                        <div class="options-menu">
                          <button type="button" name="switch contrast" class="contrast" @click="switchContrast"><icon icon="eye" /></button>
<!--                          <template  v-for="(item, i) in nav_menus[1].links" >-->
<!--                            <div v-if="item.link.title === 'Facebook'" :key="i">-->
<!--                              <a :href="item.link.url" target="_blank" class="ml-0">-->
<!--                                <img src="./../assets/media/sm/icon-facebook.svg">-->
<!--                              </a>-->
<!--                            </div>-->
<!--                            <div v-if="item.link.title === 'You Tube'" :key="i">-->
<!--                              <a :href="item.link.url" target="_blank">-->
<!--                                <img src="./../assets/media/sm/icon-youtube.svg">-->
<!--                              </a>-->
<!--                            </div>-->
<!--                          </template>-->
                          <div>
                              <a :href="sm.facebook" target="_blank" class="ml-0">
                                <img src="./../assets/media/sm/icon-facebook.svg">
                              </a>
                              <a :href="sm.youtube" target="_blank" class="ml-1">
                                <img src="./../assets/media/sm/icon-youtube.svg">
                              </a>
                          </div>
                          <div class="resize">
                                <button type="button" name="decrease zoom" class="resize-button decrease" @click="decreaseFont">Aa-</button>
                                <span class="resize-value" @click="resetFont" title="Kliknij by zresetować">{{ zoomValue }}<span>%</span></span>
                                <button type="button" name="increase zoom" class="resize-button increase" @click="increaseFont">Aa+</button>
                            </div>
                        </div>
                        <nav-menu></nav-menu>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </nav>
</template>

<script>
    import Icon from "./utility/Icon";
    import NavMenu from "./menu/NavMenu";

    export default {
        components: {
            NavMenu,
            Icon,
        },

        data: function(){
            return{
                collapse: false,
                scrollPosition: {
                    actual: null,
                    before: null,
                },
            }
        },

        methods: {
            updateScroll() {
                this.scrollPosition.before = this.scrollPosition.actual
                this.scrollPosition.actual = window.scrollY
            },
            switchContrast() {
                this.$store.commit('SWITCH_CONTRAST')
            },
            decreaseFont() {
                this.$store.commit('DEC_FONT');
            },
            increaseFont() {
                this.$store.commit('INC_FONT');
            },
            resetFont() {
                this.$store.commit('RESET_FONT');
            },
        },

        computed: {
            scrollTop: function() { return this.scrollPosition.before - this.scrollPosition.actual },
            zoomValue: function() { return this.$store.state.fontsize / 0.16 },
            sm() {
              return this.$store.getters.setting('social_media')
            }
        },

        mounted() {
            window.addEventListener('scroll', this.updateScroll);
        },

        watch: {
            '$route' () {
                this.collapse = false
            }
        },

        destroy() {
            window.removeEventListener('scroll', this.updateScroll)
        },
    }
</script>

<style lang="scss">
    nav.navigation-bar{
        background: $white;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        padding-top: 15px;
        padding-bottom: 15px;
        z-index: 99;
        box-shadow: inset 0 -1px 0 0 $gray-100;
        transition: 0.5s ease;

        @include media-breakpoint-up(lg){
            position: sticky;
            padding-top: 0;
            padding-bottom: 0;

            &.scroll-top{}

            &.scroll-bottom{
                $offset: 48px;
                top: -$offset;

                .logo{
                    transform-origin: bottom;
                    transform: scale(0.6) translateY(16px);
                }
            }
        }

        .logo{
            width: auto;
            max-height: 50px;
            transition: 0.5s ease;

            @include media-breakpoint-up(lg){
                max-height: 70px;
            }
        }

        .collapse-menu{
            @include media-breakpoint-down(md){
                max-height: 72.5vh;
                overflow-y: auto;
            }
        }

        .hamburger-menu{
            $size: 24px; // Size of squere
            $height: 3px; // Lines thickness
            $inactiveColor: $black;
            $activeColor: $primary;
            $transition: 0.24s ease;
            $center: calc( (#{$size} - #{$height}) / 2 );

            position: relative;
            display: flex;
            width: $size;
            height: $size;
            border: none;
            background: transparent;
            padding: 0;

            &:focus{ outline: none; }

            span{
                position: absolute;
                top: $center;

                &::before, &::after{
                    position: absolute;
                    content: " ";
                }

                &, &::before, &::after{
                    height: $height;
                    width: 100%;
                    left: 0;
                    background: $inactiveColor;
                    transition: $transition;
                    border-radius: 4px;
                }

                &::before{
                    top: calc( #{$center} * -0.65 );
                }

                &::after{
                    bottom: calc( #{$center} * -0.75 );
                }
            }

            &.open{
                span{
                    background: transparent;

                    &::before, &::after{
                        top: 0;
                        background: $activeColor;
                    }

                    &::before{ transform: rotate(45deg); }
                    &::after{ transform: rotate(-45deg); }
                }
            }
        }

        .options-menu{
            // delete this when will be working
            .lang img{
                pointer-events: none;
                opacity: 0.2;
            }
            //

            background-color: $primary;
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 48px;

            &>*{ margin-left: 8px; &:last-child { margin-left: 24px;} }

            @include media-breakpoint-up(lg){
                &>*{ margin-left: 8px; &:last-child { margin-left: 48px;} }
            }

            .contrast{
                background: none;
                border: 1px solid $white;
                padding: 6px;
                display: flex;

                .icon{
                    width: 20px;
                    // height: 20px;
                }

                &:hover{
                    background: $black;

                    svg path{ fill: $white; }
                }
            }

            .resize{
                display: flex;
                flex-direction: row;
                align-items: center;

                &-value{
                    display: inline-flex;
                    justify-content: center;
                    align-items: flex-end;
                    color: $black;
                    background: $gray-100;
                    @extend %navigation-resize-value;
                    margin: 0 4px;
                    min-width: 64px;
                    text-align: center;
                    padding: 9px 12px;
                    cursor: pointer;

                    &>span{
                        font-size: 0.8em;
                        margin-left: 1px;
                        margin-bottom: 1px;
                    }
                }

                &-button{
                    @extend %navigation-resize-button;
                    background: none;
                    border: none;
                    padding: 6px;
                    color: $white;
                }


            }

            .lang{
                position: relative;
                display: flex;
                height: 20px;
            }
        }
    }

    // Because of navbar height on <992px (lg)
    nav.navigation-bar + main{
        margin-top: 80px;

        @include media-breakpoint-up(lg){
            margin-top: 0;
        }
    }

    // Contrast
    .contrast nav.navigation-bar{
        box-shadow: inset 0 -1px 0 0 $gray-500;
        background: $black;

        .nav-menu {
          a {
            color: $white;
          }
        }

        .options-menu{
            .contrast{
                background: $black;

                svg path{ fill: $white; }
            }
        }
    }
</style>
