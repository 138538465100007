<template>
    <li v-if="submenu.length === 0" class="nav-menu-item"><router-link :to="link">{{ title }}</router-link></li>
    <li v-else class="nav-menu-item">
        <a href="#" class="nav-menu-dropdown-title" @click="dropdown = !dropdown">{{ title }} &#x25BE;</a>
        <ul class="nav-menu-dropdown" :class="dropdown ? 'open' : ''">
            <li v-for="(m,i) in submenu" :key="i" class="nav-menu-dropdown-item"><router-link :to="getUrl(m)">{{ m.title }}</router-link></li>
        </ul>
    </li>
</template>

<script>
    export default {
        name: "MenuItem",
        props: {
            link: String,
            title: String,
            submenu: {
                type: Array,
                default: function() { return []; }
            }
        },
        data() {
            return {
                dropdown: false
            }
        },
        methods: {
            getUrl(item) {
                return item.url.replace(/^.*\/\/[^/]+/, '')
            }
        }
    }
</script>

<style scoped lang="scss">
    .nav-menu {
        &-item{
            &>a{
                position: relative;
                display: inline-flex;
                padding: 16px 24px;
                @extend %navigation-menu;
                color: $black;
                cursor: pointer;
                width: 100%;

                &.disabled{
                    background: $gray-100;
                    pointer-events: none;
                    color: $gray-500;
                }

                &.nav-menu-dropdown-title{
                    cursor: default;
                    
                    &::after{
                        z-index: 1;
                        content: " ";
                        position: absolute;
                        bottom: 0;
                        border-width: 0 16px 4px 16px;
                        border-color: transparent transparent $primary transparent;
                    }
                }

                &.router-link-active, &.router-link-exact-active{ color: $primary; }

                @include media-breakpoint-up(lg){
                    &:hover{
                        background: $primary;
                        color: $white;
                    }
                }
            }

            &>a, &>a:hover{ text-decoration: none; }

            @include media-breakpoint-up(lg){
                // Showing dropdown menu
                .nav-menu-dropdown-title:hover + .nav-menu-dropdown,
                .nav-menu-dropdown-title:focus + .nav-menu-dropdown
                {
                    opacity: 1;
                    pointer-events: auto;
                }
            }
        }
        
        &-dropdown{
            display: none;
            &.open{ display: flex; }

            flex-direction: column;
            list-style-type: none;
            background: $white;
            margin: 0;
            padding: 0;
            border-left: 1px solid $gray-200;

            &-item{
                &>a{
                    position: relative;
                    display: inline-flex;
                    padding: 8px 16px 8px 32px;
                    @extend %navigation-menu;
                    color: $gray-800;
                    cursor: pointer;
                    width: 100%;

                    &.disabled{
                        background: $gray-100;
                        pointer-events: none;
                        color: $gray-500;
                    }

                    &.router-link-active, &.router-link-exact-active{ color: $primary; }

                    @include media-breakpoint-up(lg){
                        padding: 16px 24px;
                        color: $black;

                        &:hover{
                            background: $primary;
                            color: $white;
                        }
                    }
                }

                &>a, &>a:hover{ text-decoration: none; }
            }

            @include media-breakpoint-up(lg){
                box-shadow: 0 8px 12px -4px rgba($black, 0.24);
                position: absolute;
                display: flex;
                border-left: none;
                opacity: 0;
                pointer-events: none;

                &:hover, &:focus, &:focus-within{
                    opacity: 1;
                    pointer-events: auto;
                }
            }
        }
    }
</style>