import axios from 'axios'

class ApiClient {
    constructor(apiUrl) {
        this.apiUrl = apiUrl
    }

    getFrontpage() {
        return axios.get(this.apiUrl + '/ex/frontpage')
    }

    getViewsCounter() {
        return axios.get(this.apiUrl + '/ex/view-counter')
    }

    getAllPages() {
        return this.getAllPosts('pages')
    }

    getPage(slug) {
        return this.getPost('pages', slug)
    }

    getAllProjects() {
        return this.getAllPosts('project')
    }

    getProject(slug) {
        return this.getPost('project', slug)
    }

    getNavMenu(location) {
        return axios.get(this.apiUrl + '/ex/menu?location=' + location)
    }

    getFeaturedPosts() {
        return axios.get(this.apiUrl + '/ex/featured-posts?_embed')
    }

    getSettings() {
        return axios.get(this.apiUrl + '/acf/v3/options/option')
    }

    getViesCounter() {
        return axios.get(this.apiUrl + '/ex/view-counter')
    }

    getTags() {
        return new Promise((resolve, reject) => {
            axios.get(this.apiUrl + '/wp/v2/tags')
                .then((response) => {
                    resolve(response.data)
                })
                .catch(error => reject(error))
        })
    }

    getPostPage(type, page = 1) {
        return new Promise((resolve, reject) => {
            axios.get(this.apiUrl + '/wp/v2/' + type + '?_embed&per_page=12&page=' + page)
                .then((response) => {
                    resolve(response)
                })
                .catch(error => reject(error))
        })
    }

    getAllPosts(type, page, perPage) {
        return new Promise((resolve, reject) => {
            axios.get(this.apiUrl + '/wp/v2/' + type + '?_embed&per_page=' + perPage + '&page=' + page)
                .then((response) => {
                    resolve(response)
                })
                .catch(error => reject(error))
        })
    }

    getPost(type, slug)
    {
        return new Promise((resolve, reject) => {
            axios.get(this.apiUrl + '/wp/v2/'+type+'?slug=' + slug + '&_embed')
                .then((response) => {
                    if(response.data.length > 0) resolve(response.data[0])
                    else reject()
                })
                .catch(error => reject(error))
        })
    }

    search(type, search) {
        return new Promise((resolve, reject) => {
            axios.get(this.apiUrl + '/wp/v2/'+type+'?search=' + search + '&_embed&per_page=20')
                .then((response) => {
                    resolve(response.data)
                })
                .catch(error => reject(error))
        })
    }

    sendContactForm(payload) {
        return new Promise((resolve, reject) => {
            axios.post(this.apiUrl + '/ex/contact', payload)
                .then((response) => {
                    resolve(response)
                })
                .catch(error => reject(error))
        })
    }

    sendNewsletterForm(email, name, optional_agreement) {
        return new Promise((resolve, reject) => {
            axios.post(this.apiUrl + '/ex/newsletter', { email: email, name: name, optional_agreement: optional_agreement })
                .then((response) => {
                    resolve(response)
                })
                .catch(error => reject(error))
        })
    }
}

export default ApiClient
